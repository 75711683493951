import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dropdown from "react-kiwi-dropdown";
import { breakpoints } from "../../styles/variables";

interface KantonSelectProps {
  kantone: [{}];
  setKanton: React.Dispatch<React.SetStateAction<string>>;
  stateLoc: string;
}

const StyledDropdown = styled(Dropdown)`
  z-index: 1;
  color: black;
  * {
    scrollbar-width: none;
  }

  *::-webkit-scrollbar {
    background: transparent;
    width: 0px;
    display: none; /* for Chrome, Safari, and Opera */
  }

  .KIWI-button {
    background: #e6e6e6;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    border: none;
    background-image: url("/assets/gestaltungselemente/select-arrow-down.svg");
    background-position: 93% center;
    background-repeat: no-repeat;
    background-size: 14.97px 6.41px;
    padding: 3px 7px;
    font: 13px / 18px "Oswald Regular";
    cursor: pointer;
    color: black;
  }

  .KIWI-button-indicator {
    background: green;

    &.selected {
      background: pink;
    }
  }

  .KIWI-option.selected::after {
    content: " ";
    width: 20px;
    height: 20px;
    background: url("/assets/gestaltungselemente/option-deactivate.svg");
    position: absolute;
    background-size: 20px;
    right: 33px;
  }

  .KIWI-single-arrow {
    display: none;
  }

  .KIWI-option-list {
    padding: 0;
    box-shadow: none;
    width: calc((100vw - 68px) / 2);
    max-height: 290px;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .KIWI-option {
    background: #4d4d4d;
    color: white;
    font: 13px / 40px "Oswald Regular";
    padding: 3px 7px;
    cursor: pointer;

    &.selected,
    &:hover {
      background: #e6e6e6;
      color: black;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .KIWI-option,
    .KIWI-button {
      padding: 11px 7px;

      font: 14px / 18px "Oswald Regular";
    }

    .KIWI-option-list {
      padding: 0;
      width: calc((100vw - 164px) / 4);
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    .KIWI-button {
      background-size: 19.2px 8.23px;
    }
    .KIWI-button,
    .KIWI-option {
      font: 16px / 24px "Oswald Regular";
      padding: 5px 8px;
      width: calc((100vw - 184px) / 4);
    }
  }

  //option-deactivate.svg

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .KIWI-button {
      background-size: 24px 10.29px;
    }

    .KIWI-option,
    .KIWI-button {
      font: 21px / 32px "Oswald Regular";
      padding: 6px 10px;
      //width: calc((1160px - ((93px + 4.7vw) * 2)) / 4);
      width: calc((1280px - 184px) / 4);
      width: calc((100vw - 120px - ((93px + 4.7vw) * 2)) / 4);
    }
    .KIWI-option-list {
      padding: 0;
      width: calc((1280px - 184px) / 4);

      width: calc((100vw - 120px - ((93px + 4.7vw) * 2)) / 4);
    }
  }

  @media screen AND (min-width: 1440px) {
    .KIWI-option,
    .KIWI-button {
      //width: calc((1160px - ((93px + 4.7vw) * 2)) / 4);
      width: calc((1280px - 120px) / 4);
    }

    .KIWI-option-list {
      padding: 0;
      width: calc((1280px - 120px) / 4);
    }
  }
`;

const KantonSelect: React.FC<KantonSelectProps> = ({
  kantone,
  setKanton,
  stateLoc,
  country,
}) => {
  const options = kantone.map((entry) => {
    return { value: entry, content: entry };
  });

  //rm "*"
  const [selectedOption, setSelectedOption] = useState("*");

  const onChange = (option) => {
    if (option.value === selectedOption) {
      setSelectedOption("*");
    } else {
      setSelectedOption(option.value);
    }
  };

  useEffect(() => {
    setKanton(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption("*");
  }, [country]);

  return (
    <StyledDropdown
      options={options}
      onChange={onChange}
      buttonLabel={selectedOption !== "*" ? selectedOption : stateLoc}
      resetValue={"*"}
      selectedOption={selectedOption}
    />
  );
};

export default KantonSelect;
