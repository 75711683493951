import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import KantonSelect from "./KantonSelect";
import LandSelect from "./LandSelect";

import "../../styles/select-input.css";

interface Country {
  name: string;
  latitude: number;
  longitude: number;
  zoom: number;
  filterTitle: string;
}

interface TableHeadProps {
  countries: {
    ch: Country;
    at: Country;
    de: Country;
    us: Country;
    it: Country;
  };
  kantone: [];
  setKanton: React.Dispatch<React.SetStateAction<string>>;
  setCountry: React.Dispatch<React.SetStateAction<object>>;
  siteId: number;
  stateLoc: string;
  countriesWithEntries: [];
}

const HeadDiv = styled.div`
  background: ${colors.green};
  padding: 16px 23px;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 21px;
    row-gap: 9px;
  }
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 16px 37px;

    .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 30px;

      > div:first-of-type {
        grid-column: 2;
      }
      > div:last-of-type {
        grid-column: 3;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 21px 47px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 26px calc(93px + 4.7vw);

    .grid {
      column-gap: 40px;
      max-width: 1280px;
      margin: 0 auto;
    }
  }
`;

const TableHead: React.FC<TableHeadProps> = ({
  countries,
  setCountry,
  kantone,
  setKanton,
  siteId,
  stateLoc,
  countriesWithEntries,
  country,
}) => (
  <HeadDiv>
    <div className="grid">
      <LandSelect
        countries={countries}
        setCountry={setCountry}
        siteId={siteId}
        countriesWithEntries={countriesWithEntries}
      />
      <KantonSelect
        stateLoc={stateLoc}
        kantone={kantone}
        country={country}
        setKanton={setKanton}
      />
    </div>
  </HeadDiv>
);

export default TableHead;
